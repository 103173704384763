@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bb505000;
}

:hover::-webkit-scrollbar-thumb {
  background: #cecacab2;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cecaca00;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
